g {
  cursor: pointer;
}
.st0 {
  fill: #89c1c1;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #89c1c1;
}
.st2 {
  fill: #00a0e9;
}
.st3 {
  fill: #108c8f;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e60012;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill: #118689;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #108c8f;
}
svg {
  height: 100%;
  width: 100%;
}
@-webkit-keyframes myfirst {
0% {
    opacity: 1;
}
50% {
    opacity: 0.7;
}
100% {
    opacity: 1;
}
}
@keyframes myfirst {
0% {
    opacity: 1;
}
50% {
    opacity: 0.7;
}
100% {
    opacity: 1;
}
}
.svgicon {
  -webkit-animation: myfirst 1.5s infinite;
  animation: myfirst 1.5s infinite;
}